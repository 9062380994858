import React from 'react';
import { useIntl } from 'react-intl';
import {
  Banner,
  Brands,
  WhoWork,
  WhyWork,
  RecommendedSection,
} from './components';

const BusinessPage = () => {
  return (
    <main>
      <Banner />
      <WhoWork />
      <WhyWork />
      <RecommendedSection />
      <Brands />
    </main>
  );
};

export default BusinessPage;

export const Head = () => {
  const intl = useIntl();
  return (
    <>
      <title>{intl.formatMessage({ id: 'businessAreaSeoTitle' })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: 'businessAreaSeoDescription' })}
      />
      <meta
        name="keywords"
        content={intl.formatMessage({ id: 'businessAreaSeoKeywords' })}
      />
      <meta name="robots" content={intl.formatMessage({ id: 'seoRobots' })} />
      <link
        rel="canonical"
        href={intl.formatMessage({ id: 'businessAreaOgUrl' })}
      />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: 'businessAreaSeoTitle' })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({ id: 'businessAreaSeoDescription' })}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={intl.formatMessage({ id: 'businessAreaOgUrl' })}
      />
      <meta
        property="og:image"
        content={intl.formatMessage({ id: 'ogImage' })}
      />
      <meta
        name="twitter:card"
        content={intl.formatMessage({ id: 'twitterCard' })}
      />
      <meta
        name="twitter:title"
        content={intl.formatMessage({ id: 'businessAreaSeoTitle' })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({ id: 'businessAreaSeoDescription' })}
      />
      <meta
        name="twitter:image"
        content={intl.formatMessage({ id: 'twitterImage' })}
      />
    </>
  );
};
