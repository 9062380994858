import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';

import * as styles from './brands.module.scss';
import BrandsGallery from './brandsGallery';

const Brands = ({ intl: { formatMessage } }) => {
  return (
    <div className={styles.brands}>
      <div className={styles.background_images}>
        <StaticImage
          src="../../../images/arrow_10.webp"
          alt="arrow"
          className={styles.brands__arrow_1}
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
          objectFit="contain"
        />
      </div>
      <h3 className={styles.title}>
        {formatMessage({ id: 'businessTalentBrandsTitle' })}
      </h3>
      <br />
      <br />
      <BrandsGallery />
    </div>
  );
};

Brands.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Brands);
